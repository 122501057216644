import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from '@firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyBQsOxrwu2YNJOil4xTbpvsv2jI7AfgIMs',
  authDomain: 'squatch-swatch.firebaseapp.com',
  databaseURL: 'https://squatch-swatch-default-rtdb.firebaseio.com',
  projectId: 'squatch-swatch',
  storageBucket: 'squatch-swatch.appspot.com',
  messagingSenderId: '798804003362',
  appId: '1:798804003362:web:9a8e78f44ea1135a4bd87b',
}

const app = initializeApp(firebaseConfig)

export const db = getFirestore(app)

export const auth = getAuth(app)
