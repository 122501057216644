import React, { useEffect } from 'react'
import SwatchPickerStyle from './SwatchPickerStyle'
import chroma from 'chroma-js'
import { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const SwatchDiv = ({ bg }) => {
  const [textColor, setTextColor] = useState()
  const [copied, setCopied] = useState()
  const [showCopy, setShowCopy] = useState(false)

  const BGColor = bg.color

  useEffect(() => {
    if (chroma(BGColor).luminance() < 0.2) {
      setTextColor('text-white')
    } else {
      setTextColor('text-black')
    }
  }, [BGColor])

  const copyDisplay = () => {
    setShowCopy(true)
    setTimeout(() => {
      setShowCopy(false)
    }, 1000)
  }

  return (
    <CopyToClipboard text={bg.color} onCopy={() => setCopied({ copied: true })}>
      <SwatchPickerStyle bg={bg.color}>
        <div
          className="swatch-div d-flex justify-content-between flex-row-reverse "
          onClick={copyDisplay}
        >
          <div className="mx-1 my-2">
            <p className={textColor}>{bg.color}</p>
          </div>
          {showCopy && (
            <div className="copied mx-1 my-2">
              <p className={textColor}>Copied</p>{' '}
            </div>
          )}
        </div>
      </SwatchPickerStyle>
    </CopyToClipboard>
  )
}

export default SwatchDiv
