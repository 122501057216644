import React, { useState, useEffect } from 'react'
import ConverterStyle from '../ColorConverter/ConverterStyle'
import chroma from 'chroma-js'
import ResultDiv from './ResultDiv'

const BrightenDarken = () => {
  const [color, setColor] = useState('gray')
  const [amount, setAmount] = useState(1)
  const [colorResult, setColorResult] = useState('')

  // console.log(chroma('#567234').darken(2.6).hex())
  // console.log(chroma(`${brightenColor}`).brighten(2).hex())

  const changeColorValue = (e) => {
    setColor(e.target.value)
  }
  const changeNumber = (e) => {
    setAmount(e.target.value)
  }

  const brighten = () => {
    if (chroma.valid(color)) {
      setColorResult(chroma(`${color}`).brighten(amount).hex())
    }
  }

  const darken = () => {
    if (chroma.valid(color)) {
      setColorResult(chroma(`${color}`).darken(amount).hex())
    }
  }

  return (
    <ConverterStyle>
      <div className="border-converter box-shadow">
        <h3 className="header-converter text-center p-2 mb-0">Light / Dark</h3>
        <input
          type="text"
          placeholder="blue"
          className="input-converter mb-3 text-center"
          onChange={changeColorValue}
        />
        <input
          type="number"
          placeholder={1}
          className="input-converter mb-2 text-center"
          step="0.1"
          onChange={changeNumber}
        />
        <div className="d-flex justify-content-center border-bottom">
          <button className="BTN-left-Round my-3" onClick={brighten}>
            Brighten
          </button>
          <button className="BTN-right-Round my-3" onClick={darken}>
            Darken
          </button>
        </div>
        <div className="my-4 d-flex justify-content-center">
          <ResultDiv color={colorResult} />
        </div>
      </div>
    </ConverterStyle>
  )
}

export default BrightenDarken
