import React from 'react'
import SignInStyles from './SignInStyles'
import paintBrush from '../../Images/paintBrush.svg'
import logIn from '../../Images/login_black.svg'
import { Link } from 'react-router-dom'

const SignInWarning = () => {
  return (
    <SignInStyles>
      <div className="sign-in-warn d-flex align-items-center my-5 p-5">
        <div className="text-center">
          {/* <img
            src={paintBrush}
            alt="Painty the paint brush"
            className="warning-image-painty"
          /> */}
          <Link to="/SignIn">
            <div className="d-flex justify-content-center align-items-center">
              <h1 className="fredoka my-1 no-link-style">Please log in</h1>
              {/* <img src={logIn} className="nav-icon px-2 pt-1" /> */}
            </div>
          </Link>
          <h5 className="cramps-gray fredoka my-4">
            or sign up to start building swatches
          </h5>

          <h5 className="cramps-gray fredoka my-3">It's free</h5>
          <img
            src={paintBrush}
            alt="Painty the paint brush"
            className="warning-image-painty my-4"
          />
        </div>
      </div>
    </SignInStyles>
  )
}

export default SignInWarning
