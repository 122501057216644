import { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import SwatchPicker from './MySwatches/SwatchPicker'
import MySwatch from './MySwatches/MySwatch'
import ColorConverter from './ColorConverter/ColorConverter'
import BrightenDarken from '../Components/BrightenDarken/BrightenDarken.js'
import { auth, db } from './SignIn/firebase-config'
import { collection, getDocs } from 'firebase/firestore'
import SignInWarning from './SignIn/SignInWarning'

const MySwatches = ({ mySwatches, setMySwatches }) => {
  const [colorsInSwatch, setColorsInSwatch] = useState([])
  const [name, setSwatchName] = useState('Please Add A Name')
  const [user, setUser] = useState('test@mail.com')

  const userCollectionRef = collection(db, user)

  useEffect(() => {
    const getUser = async () => {
      const data = await getDocs(userCollectionRef, user)
      setUser(auth.currentUser.email)
    }
    getUser()
  }, [])

  useEffect(() => {
    const getSwatches = async () => {
      const data = await getDocs(userCollectionRef, user)
      setMySwatches(data.docs.map((doc) => ({ ...doc.data() })))
    }
    getSwatches()
  }, [user])

  return (
    <div className="col">
      <div className="row mx-5 py-3">
        <div className="col-12 col-md-3 col-xxl-2">
          <SwatchPicker
            setMySwatches={setMySwatches}
            mySwatches={mySwatches}
            colorsInSwatch={colorsInSwatch}
            setColorsInSwatch={setColorsInSwatch}
            name={name}
            setSwatchName={setSwatchName}
          />
        </div>
        <div className="col-12 col-md-6 col-xxl-8 d-flex flex-wrap justify-content-center">
          {user !== 'test@mail.com' && (
            <>
              {mySwatches.length > 0 && (
                <>
                  {mySwatches.map((swatch, index) => (
                    <MySwatch
                      swatch={swatch}
                      key={index}
                      mySwatches={mySwatches}
                      setMySwatches={setMySwatches}
                      setColorsInSwatch={setColorsInSwatch}
                      name={name}
                      setSwatchName={setSwatchName}
                    />
                  ))}
                </>
              )}
            </>
          )}
          {user === 'test@mail.com' && <SignInWarning />}
        </div>
        <div className="col-12 col-md-3 col-xxl-2">
          <div className="row">
            <div className="col-sm-6 col-md-12 mb-2">
              <ColorConverter />
            </div>
            <div className="col-sm-6 col-md-12 my-4">
              <BrightenDarken />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MySwatches
