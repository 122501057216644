import styled from 'styled-components'

const SignInStyles = styled.div`
  min-height: 82vh;
  .sign-in-card {
    border: 1px solid lightgray;
    border-radius: 0.25rem;
    background-color: white;
    height: 70%;
    width: 80%;
  }

  input {
    border: 1px solid lightgray;
    padding: 0.5rem;
    border-radius: 0.25rem;
    width: 80%;
  }
  .sign-in-warn {
    border: 1px solid lightgray;
    border-radius: 0.25rem;
    background-color: white;
    height: 75vh;
    width: 100%;
  }

  .warning-image-painty {
    width: 20%;
  }
  .no-link-style {
    color: black;
    text-decoration: underline white;
    &:hover {
      color: red;
    }
  }
`

export default SignInStyles
