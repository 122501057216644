import Swatch from './MySwatches/Swatch'
import { useState, useRef, useEffect } from 'react'
import SwatchesData from '../Data/SwatchesData2.json'
import SwatchesData2 from '../Data/SwatchesData3.json'
import paintBrush from '../Images/paintBrush.svg'
import { db } from './SignIn/firebase-config'
import { collection, getDocs } from 'firebase/firestore'

const MainPage = ({ mySwatches, setMySwatches }) => {
  const [popSwatches, setPopSwatches] = useState(SwatchesData.SwatchesData)
  const filters = [
    'red',
    'orange',
    'yellow',
    'green',
    'blue',
    'purple',
    'brown',
    'pink',
    'gray',
    'autumn',
    'summer',
    'spring',
    'winter',
    'bold',
    'light',
    'dark',
  ]
  // *********************************************************************
  const [pageNum, setPageNum] = useState(0)
  const [incomingSwatches, setIncomingSwatches] = useState(
    SwatchesData2.SwatchesData
  )

  const userCollectionRef = collection(
    db,
    `${process.env.REACT_APP_PALETTE_KEY_ONE}${pageNum}${process.env.REACT_APP_PALETTE_KEY_TWO}`
  )

  useEffect(() => {
    if (pageNum < 5) {
      const getSwatches = async () => {
        const data = await getDocs(
          userCollectionRef,
          `${process.env.REACT_APP_PALETTE_KEY_ONE}${pageNum}${process.env.REACT_APP_PALETTE_KEY_TWO}`
        )
        setIncomingSwatches(data.docs.map((doc) => ({ ...doc.data() })))
        setPopSwatches([...popSwatches, ...incomingSwatches])
      }
      getSwatches()
    }
  }, [pageNum])

  // *********************************************************************

  const sortColor = (filter) => {
    if (popSwatches) {
      const sortedColors = [...popSwatches].sort((a) => {
        if (a.style.includes(filter)) {
          return -1
        } else {
          return 0
        }
      })
      setPopSwatches(sortedColors)
    }
  }

  const myRef = useRef()
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0]
      // console.log(entry.isIntersecting)
      if (entry.isIntersecting === true) {
        setPageNum((prev) => prev + 1)
      }
    })
    observer.observe(myRef.current)
  }, [])

  return (
    <div className="d-flex justify-content-left">
      <div className="filter-button col-3 col-sm-2 col-xl-1 nav-box">
        <div className="mt-2">
          {/* <div className="text-center">
            <h4 className="text-white mb-0 search-header">
              <img src={searchIcon} className="search-icon" />
              Find
            </h4>
          </div> */}
          {filters.map((colors, index) => (
            <div
              className="d-flex justify-content-center align-items-center"
              key={index}
              onClick={() => sortColor(colors)}
            >
              <div
                className="color-div"
                style={{
                  backgroundColor: `${colors}`,
                }}
              />
              <button
                onClick={() => sortColor(colors)}
                className="my-2 fredoka-one"
                style={{
                  textDecorationColor: `${colors}`,
                }}
              >
                {colors}
              </button>
            </div>
          ))}
        </div>
      </div>
      <div className="col-9 col-sm-10 col-xl-11 mt-4">
        <div className="border-bottom mx-5 d-flex align-items-center justify-content-center">
          <h5>🎨 Popular palettes from the community 🎨</h5>
          {/* <img src={paintBrush} className="little-paintbrush mx-3" /> */}
        </div>
        <div className="d-flex justify-content-center flex-wrap">
          {popSwatches && (
            <>
              {popSwatches.map((swatch, index) => (
                <Swatch
                  mySwatches={mySwatches}
                  setMySwatches={setMySwatches}
                  swatch={swatch}
                  key={index}
                />
              ))}
            </>
          )}
        </div>
        {pageNum < 5 && <p className="mx-5 px-5 text-center">Loading...</p>}
        <div ref={myRef} />
      </div>
    </div>
  )
}

export default MainPage
