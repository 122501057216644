import { Routes, Route, Link } from 'react-router-dom'
import './App.css'
import MainPage from './Components/MainPage'
import MySwatches from './Components/MySwatches'
import { useState } from 'react'
import GlobalStyle from './GlobalStyle'
import SignIn from './Components/SignIn'
import IntroPage from './Components/IntroPage'
import Navbar from './Components/Navbar/Navbar'

//load palettes from firebase instead of json

function App() {
  const [mySwatches, setMySwatches] = useState([
    {
      name: 'Swatch Force One',
      id: 106670,
      colors: [
        {
          id: 1,
          color: '#f77956',
        },
        {
          id: 2,
          color: '#d7d7d8',
        },
      ],
    },
  ])

  return (
    <GlobalStyle>
      <Navbar />
      <main>
        <Routes>
          <Route
            path="Popular"
            element={
              <MainPage mySwatches={mySwatches} setMySwatches={setMySwatches} />
            }
          />
          <Route path="/" element={<IntroPage />} />
          <Route
            path="MySwatches"
            element={
              <MySwatches
                mySwatches={mySwatches}
                setMySwatches={setMySwatches}
              />
            }
          />
          <Route path="SignIn" element={<SignIn />} />
        </Routes>
      </main>
      <footer className="footer text-center p-2">
        <Link to="/" className="p-2 Nav-Text">
          ColorPop🎈
        </Link>
        <p>© 2022</p>
      </footer>
    </GlobalStyle>
  )
}

export default App
