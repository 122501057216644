import balloon from '../Images/balloon.png'
import IntroStyles from './Intro/IntroStyles'
import examplePalette from '../Images/examplePalette.png'
import paletteIcon from '../Images/paletteIcon.svg'
import login_black from '../Images/login_black.svg'
import star_black from '../Images/grade_black.svg'
import paintCan from '../Images/paintCan.svg'
import paintPalette from '../Images/paintPalette.svg'
import { Typewriter } from 'react-simple-typewriter'
import { Link } from 'react-router-dom'

//typewriter effect
//Links
//bottom paragraph: it's free, beta test, project updates, data migration

const IntroPage = () => {
  return (
    <IntroStyles>
      <div className="col fredoka">
        <div className="row g-0 pt-2 d-flex align-items-center border-bottom p-4 mx-4 pt-5 pb-5">
          <div className="col-12 col-lg-6 text-center fredoka">
            <h1 className="fredoka-one mt-5">Welcome to ColorPop</h1>
            <h3 className="my-5">
              Your color solution for
              <span
                style={{ color: 'red', fontWeight: 'bold' }}
                className="mx-2"
              >
                {/* Style will be inherited from the parent element */}
                <Typewriter
                  words={['websites', 'apps', 'games', 'art', 'everything!']}
                  loop={50}
                  cursor
                  cursorStyle="_"
                  typeSpeed={100}
                  deleteSpeed={70}
                  delaySpeed={1000}
                />
              </span>
            </h3>
            <p>👋Sign up for free!</p>
          </div>
          <div className="col-12 col-lg-6 d-flex justify-content-center">
            <img
              src={paintCan}
              alt="paint can cartoon"
              className="intro-characters"
            />
          </div>
        </div>

        <div className="row g-0 border-bottom m-4 pb-4">
          <div className="col-12 text-center fredoka-one d-flex justify-content-around">
            <Link to="/Popular" className="intro-nav">
              <div className="d-flex justify-content-around">
                <img src={star_black} />
                <h3 className="m-2">Popular</h3>
              </div>
            </Link>
            <Link to="/MySwatches" className="intro-nav">
              <div className="d-flex justify-content-around">
                <img src={paletteIcon} />
                <h3 className="m-2">Build</h3>
              </div>
            </Link>
            <Link to="/SignIn" className="intro-nav">
              <div className="d-flex justify-content-around">
                <img src={login_black} />
                <h3 className="m-2">LogIn/SignUp</h3>
              </div>
            </Link>
          </div>
          <h5 className="text-center px-5 py-3">
            With ColorPop you can easily design palettes with custom colors,
            convert between HEX, RGB, HSL, or names. Explore new palettes from
            the community or sign up to build your own. ColorPop is built for
            designers, artists or anyone wanting to add a splash of color to
            anything!
          </h5>
        </div>

        <div className="row g-0 border-bottom p-4 m-4">
          <div className="col-12 col-lg-6 d-flex justify-content-center">
            <img
              src={paintPalette}
              alt="paint palette cartoon"
              className="intro-characters"
            />
          </div>
          <div className="col-12 col-lg-6 d-flex align-items-center p-3 fredoka-one">
            <h2>Create, Design, Explore New Colors</h2>
            <img src={examplePalette} className="example-palette p-3 mx-5" />
          </div>
        </div>
        <h5 className="mb-0 p-3 pb-4 text-center">
          This project is currently in beta testing, we look forward to adding
          more features soon!
        </h5>
      </div>
    </IntroStyles>
  )
}

export default IntroPage
