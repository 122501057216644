import { HexColorPicker } from 'react-colorful'
import { RgbStringColorPicker, HslStringColorPicker } from 'react-colorful'
import { useState, useEffect } from 'react'
import SwatchPickerStyle from './SwatchPickerStyle'
import chroma from 'chroma-js'
import PaletteDiv from './PaletteDiv'
import { auth, db } from '../SignIn/firebase-config'
import { collection, doc, setDoc } from 'firebase/firestore'

const SwatchPicker = (props) => {
  const [color, setColor] = useState('#985f2a')
  const [colorFormat, setColorFormat] = useState('hex')
  const [colorRemover, setColorRemover] = useState(false)
  const [addButton, setAddButton] = useState(false)
  const [user, setUser] = useState('yes@mail.com')

  const userCollectionRef = collection(db, user)

  useEffect(() => {
    if (auth.currentUser) {
      setUser(auth.currentUser.email)
    }
  }, [auth])

  const hexFormat = () => {
    setColorFormat('hex')
    setColor(chroma(color).hex())
  }
  const rgbaFormat = () => {
    setColorFormat('rgba')
  }
  const hslaFormat = () => {
    setColorFormat('hsla')
  }

  const addColor = () => {
    const id = Math.floor(Math.random() * 10000) + 1
    const newColor = { id, color }
    props.setColorsInSwatch([...props.colorsInSwatch, newColor])
    if (props.colorsInSwatch.length === 9) {
      setAddButton(true)
    }
  }

  const saveButton = async () => {
    const id = Math.floor(Math.random() * 1000000) + 1
    const colors = props.colorsInSwatch
    const newSwatch = { id: id, colors, name: props.name }
    props.setMySwatches([...props.mySwatches, newSwatch])

    //TODO: refactor
    if (colors.length === 1) {
      setDoc(doc(userCollectionRef, `${newSwatch.name}`), {
        name: `${newSwatch.name}`,
        id: `${newSwatch.id}`,
        colors: [
          {
            color: `${props.colorsInSwatch[0].color},`,
            id: `${props.colorsInSwatch[0].id}`,
          },
        ],
      })
    }
    if (colors.length === 2) {
      setDoc(doc(userCollectionRef, `${newSwatch.name}`), {
        name: `${newSwatch.name}`,
        id: `${newSwatch.id}`,
        colors: [
          {
            color: `${props.colorsInSwatch[0].color}`,
            id: `${props.colorsInSwatch[0].id}`,
          },
          {
            color: `${props.colorsInSwatch[1].color}`,
            id: `${props.colorsInSwatch[1].id}`,
          },
        ],
      })
    }
    if (colors.length === 3) {
      setDoc(doc(userCollectionRef, `${newSwatch.name}`), {
        name: `${newSwatch.name}`,
        id: `${newSwatch.id}`,
        colors: [
          {
            color: `${props.colorsInSwatch[0].color}`,
            id: `${props.colorsInSwatch[0].id}`,
          },
          {
            color: `${props.colorsInSwatch[1].color}`,
            id: `${props.colorsInSwatch[1].id}`,
          },
          {
            color: `${props.colorsInSwatch[2].color}`,
            id: `${props.colorsInSwatch[2].id}`,
          },
        ],
      })
    }
    if (colors.length === 4) {
      setDoc(doc(userCollectionRef, `${newSwatch.name}`), {
        name: `${newSwatch.name}`,
        id: `${newSwatch.id}`,
        colors: [
          {
            color: `${props.colorsInSwatch[0].color}`,
            id: `${props.colorsInSwatch[0].id}`,
          },
          {
            color: `${props.colorsInSwatch[1].color}`,
            id: `${props.colorsInSwatch[1].id}`,
          },
          {
            color: `${props.colorsInSwatch[2].color}`,
            id: `${props.colorsInSwatch[2].id}`,
          },
          {
            color: `${props.colorsInSwatch[3].color}`,
            id: `${props.colorsInSwatch[3].id}`,
          },
        ],
      })
    }
    if (colors.length === 5) {
      setDoc(doc(userCollectionRef, `${newSwatch.name}`), {
        name: `${newSwatch.name}`,
        id: `${newSwatch.id}`,
        colors: [
          {
            color: `${props.colorsInSwatch[0].color}`,
            id: `${props.colorsInSwatch[0].id}`,
          },
          {
            color: `${props.colorsInSwatch[1].color}`,
            id: `${props.colorsInSwatch[1].id}`,
          },
          {
            color: `${props.colorsInSwatch[2].color}`,
            id: `${props.colorsInSwatch[2].id}`,
          },
          {
            color: `${props.colorsInSwatch[3].color}`,
            id: `${props.colorsInSwatch[3].id}`,
          },
          {
            color: `${props.colorsInSwatch[4].color}`,
            id: `${props.colorsInSwatch[4].id}`,
          },
        ],
      })
    }
    if (colors.length === 6) {
      setDoc(doc(userCollectionRef, `${newSwatch.name}`), {
        name: `${newSwatch.name}`,
        id: `${newSwatch.id}`,
        colors: [
          {
            color: `${props.colorsInSwatch[0].color}`,
            id: `${props.colorsInSwatch[0].id}`,
          },
          {
            color: `${props.colorsInSwatch[1].color}`,
            id: `${props.colorsInSwatch[1].id}`,
          },
          {
            color: `${props.colorsInSwatch[2].color}`,
            id: `${props.colorsInSwatch[2].id}`,
          },
          {
            color: `${props.colorsInSwatch[3].color}`,
            id: `${props.colorsInSwatch[3].id}`,
          },
          {
            color: `${props.colorsInSwatch[4].color}`,
            id: `${props.colorsInSwatch[4].id}`,
          },
          {
            color: `${props.colorsInSwatch[5].color}`,
            id: `${props.colorsInSwatch[5].id}`,
          },
        ],
      })
    }
    if (colors.length === 7) {
      setDoc(doc(userCollectionRef, `${newSwatch.name}`), {
        name: `${newSwatch.name}`,
        id: `${newSwatch.id}`,
        colors: [
          {
            color: `${props.colorsInSwatch[0].color}`,
            id: `${props.colorsInSwatch[0].id}`,
          },
          {
            color: `${props.colorsInSwatch[1].color}`,
            id: `${props.colorsInSwatch[1].id}`,
          },
          {
            color: `${props.colorsInSwatch[2].color}`,
            id: `${props.colorsInSwatch[2].id}`,
          },
          {
            color: `${props.colorsInSwatch[3].color}`,
            id: `${props.colorsInSwatch[3].id}`,
          },
          {
            color: `${props.colorsInSwatch[4].color}`,
            id: `${props.colorsInSwatch[4].id}`,
          },
          {
            color: `${props.colorsInSwatch[5].color}`,
            id: `${props.colorsInSwatch[5].id}`,
          },
          {
            color: `${props.colorsInSwatch[6].color}`,
            id: `${props.colorsInSwatch[6].id}`,
          },
        ],
      })
    }
    if (colors.length === 8) {
      setDoc(doc(userCollectionRef, `${newSwatch.name}`), {
        name: `${newSwatch.name}`,
        id: `${newSwatch.id}`,
        colors: [
          {
            color: `${props.colorsInSwatch[0].color}`,
            id: `${props.colorsInSwatch[0].id}`,
          },
          {
            color: `${props.colorsInSwatch[1].color}`,
            id: `${props.colorsInSwatch[1].id}`,
          },
          {
            color: `${props.colorsInSwatch[2].color}`,
            id: `${props.colorsInSwatch[2].id}`,
          },
          {
            color: `${props.colorsInSwatch[3].color}`,
            id: `${props.colorsInSwatch[3].id}`,
          },
          {
            color: `${props.colorsInSwatch[4].color}`,
            id: `${props.colorsInSwatch[4].id}`,
          },
          {
            color: `${props.colorsInSwatch[5].color}`,
            id: `${props.colorsInSwatch[5].id}`,
          },
          {
            color: `${props.colorsInSwatch[6].color}`,
            id: `${props.colorsInSwatch[6].id}`,
          },
          {
            color: `${props.colorsInSwatch[7].color}`,
            id: `${props.colorsInSwatch[7].id}`,
          },
        ],
      })
    }
    if (colors.length === 9) {
      setDoc(doc(userCollectionRef, `${newSwatch.name}`), {
        name: `${newSwatch.name}`,
        id: `${newSwatch.id}`,
        colors: [
          {
            color: `${props.colorsInSwatch[0].color}`,
            id: `${props.colorsInSwatch[0].id}`,
          },
          {
            color: `${props.colorsInSwatch[1].color}`,
            id: `${props.colorsInSwatch[1].id}`,
          },
          {
            color: `${props.colorsInSwatch[2].color}`,
            id: `${props.colorsInSwatch[2].id}`,
          },
          {
            color: `${props.colorsInSwatch[3].color}`,
            id: `${props.colorsInSwatch[3].id}`,
          },
          {
            color: `${props.colorsInSwatch[4].color}`,
            id: `${props.colorsInSwatch[4].id}`,
          },
          {
            color: `${props.colorsInSwatch[5].color}`,
            id: `${props.colorsInSwatch[5].id}`,
          },
          {
            color: `${props.colorsInSwatch[6].color}`,
            id: `${props.colorsInSwatch[6].id}`,
          },
          {
            color: `${props.colorsInSwatch[7].color}`,
            id: `${props.colorsInSwatch[7].id}`,
          },
          {
            color: `${props.colorsInSwatch[8].color}`,
            id: `${props.colorsInSwatch[8].id}`,
          },
        ],
      })
    }
    if (colors.length === 10) {
      setDoc(doc(userCollectionRef, `${newSwatch.name}`), {
        name: `${newSwatch.name}`,
        id: `${newSwatch.id}`,
        colors: [
          {
            color: `${props.colorsInSwatch[0].color}`,
            id: `${props.colorsInSwatch[0].id}`,
          },
          {
            color: `${props.colorsInSwatch[1].color}`,
            id: `${props.colorsInSwatch[1].id}`,
          },
          {
            color: `${props.colorsInSwatch[2].color}`,
            id: `${props.colorsInSwatch[2].id}`,
          },
          {
            color: `${props.colorsInSwatch[3].color}`,
            id: `${props.colorsInSwatch[3].id}`,
          },
          {
            color: `${props.colorsInSwatch[4].color}`,
            id: `${props.colorsInSwatch[4].id}`,
          },
          {
            color: `${props.colorsInSwatch[5].color}`,
            id: `${props.colorsInSwatch[5].id}`,
          },
          {
            color: `${props.colorsInSwatch[6].color}`,
            id: `${props.colorsInSwatch[6].id}`,
          },
          {
            color: `${props.colorsInSwatch[7].color}`,
            id: `${props.colorsInSwatch[7].id}`,
          },
          {
            color: `${props.colorsInSwatch[8].color}`,
            id: `${props.colorsInSwatch[8].id}`,
          },
          {
            color: `${props.colorsInSwatch[9].color}`,
            id: `${props.colorsInSwatch[9].id}`,
          },
        ],
      })
    }
  }

  const clearButton = () => {
    props.setColorsInSwatch([])
    props.setSwatchName('')
    setAddButton(false)
  }

  const removeClickHandler = () => {
    setColorRemover(!colorRemover)
  }

  const deleteColor = (id) => {
    props.setColorsInSwatch(
      props.colorsInSwatch.filter((color) => color.id !== id)
    )
    if (props.colorsInSwatch.length < 11) {
      setAddButton(false)
    }
  }

  const customInput = (e) => {
    if (chroma.valid(e.target.value)) {
      setAddButton(false)
      setColor(e.target.value)
    } else {
      setAddButton(true)
    }
  }

  const toggleAddBTN = (e) => {
    if (chroma.valid(e.target.value)) {
      setAddButton(false)
    } else {
      setAddButton(true)
    }
  }

  return (
    <SwatchPickerStyle>
      <div className="d-flex justify-content-center">
        <div className="px-1 px-md-3 swatch-picker-border box-shadow ">
          <header>
            <h3 className="fredoka text-center">Palette Builder</h3>
          </header>
          <div className="">
            <div className="button-container d-flex justify-content-center ">
              <button className="BTN-left-Round" onClick={hexFormat}>
                HEX
              </button>
              <button className="BTN-Middle" onClick={rgbaFormat}>
                RGB
              </button>
              <button className="BTN-right-Round" onClick={hslaFormat}>
                HSL
              </button>
            </div>
            {colorFormat === 'hex' && (
              <div>
                <div className="d-flex justify-content-center my-3">
                  <HexColorPicker color={color} onChange={setColor} />
                </div>
                <div className="text-center mb-2">
                  {/* <p>{color}</p> */}
                  <input
                    key={color}
                    defaultValue={color || ''}
                    onBlur={customInput}
                    className="text-center color-input"
                    onChange={toggleAddBTN}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    className="BTN-Single"
                    onClick={addColor}
                    disabled={addButton}
                  >
                    Add Color
                  </button>
                </div>
              </div>
            )}
            {colorFormat === 'rgba' && (
              <>
                <div className="d-flex justify-content-center my-3">
                  <RgbStringColorPicker color={color} onChange={setColor} />
                </div>
                <div className="text-center">
                  {/* <p>{color}</p> */}
                  <input
                    key={color}
                    defaultValue={color || ''}
                    onBlur={customInput}
                    className="text-center color-input"
                    onChange={toggleAddBTN}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    onClick={addColor}
                    className="BTN-Single"
                    disabled={addButton}
                  >
                    Add Color
                  </button>
                </div>
              </>
            )}
            {colorFormat === 'hsla' && (
              <>
                <div className="d-flex justify-content-center my-3">
                  <HslStringColorPicker color={color} onChange={setColor} />
                </div>
                <div className="text-center">
                  {/* <p>{color}</p> */}
                  <input
                    key={color}
                    defaultValue={color || ''}
                    onBlur={customInput}
                    className="text-center color-input"
                    onChange={toggleAddBTN}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    onClick={addColor}
                    className="BTN-Single"
                    disabled={addButton}
                  >
                    Add Color
                  </button>
                </div>
              </>
            )}
          </div>
          <div>
            {props.colorsInSwatch && (
              <div className="col my-3 palette-container mx-auto">
                {props.colorsInSwatch.map((color, index) => (
                  <PaletteDiv
                    bg={color}
                    key={index}
                    onDelete={deleteColor}
                    colorRemover={colorRemover}
                    className="d-flex align-items-stretch"
                  />
                ))}
              </div>
            )}
            <div>
              <input
                value={props.name}
                className="input-name mx-2 text-center"
                type="text"
                maxLength={16}
                onChange={(e) => props.setSwatchName(e.target.value)}
              />
              <div className="d-flex justify-content-start my-3">
                <button className="BTN-left-Round" onClick={saveButton}>
                  Save Swatch
                </button>
                <button className="BTN-Middle" onClick={removeClickHandler}>
                  {colorRemover ? 'Done' : 'Remove Colors'}
                </button>
                <button className="BTN-right-Round" onClick={clearButton}>
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SwatchPickerStyle>
  )
}

export default SwatchPicker
