import styled from 'styled-components'

const SwatchPickerStyle = styled.div`
  .button-container {
    border: none;
  }
  .color-input {
    border: none;
    font-size: 1.2em;
    width: 80%;
    &:focus {
      border: 1px solid lightgray;
    }
  }

  .color-row {
    border-radius: 1rem;
  }

  .copied {
    font-weight: bold;
    animation-duration: 1s;
    animation-name: copyFade;
    position: relative;
    @keyframes copyFade {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }

  .image-x-resize {
    width: 1.2rem;
    height: 1.4rem;
  }

  .input-name {
    border-radius: 0.25rem;
    border: 1px solid lightgray;
  }

  .palette-div {
    border: 1px solid black;
    width: 100%;
    min-height: 3rem;
    background-color: ${(props) => props.bg || 'red'};
    color: ${(props) => props.textColor || 'black'};
    cursor: pointer;
    &:hover {
      border: 1px solid white;
    }
  }
  .rotate {
    writing-mode: vertical-rl;
    text-orientation: upright;
  }

  .swatch-div {
    border: 1px solid black;
    height: 2.5rem;
    width: 100%;
    background-color: ${(props) => props.bg || 'red'};
    color: ${(props) => props.textColor || 'black'};
    cursor: pointer;
    font-family: 'Fredoka';
    &:hover {
      border: 1px solid white;
    }
  }

  .swatch-div-container {
    border: 1px solid lightgray;
    min-height: 13rem;
    border-radius: 0.25rem;
    ${'' /* padding: 0.75rem; */}
    background-color: white;
    display: flex;
  }
  .swatch-container {
    border: 1px solid lightgray;
    padding: 1rem;
    border-radius: 0.25rem;
    background-color: white;
    width: 15rem;
    @media screen and (min-width: 768px) and (max-width: 850px) {
      width: 12rem;
    }
  }
  .swatch-picker-border {
    border: 1px solid lightgray;
    padding: 0.5rem;
    border-radius: 0.25rem;
    background-color: white;
  }
  .palette-container {
    min-height: 10vh;
    border: lightgray 1px solid;
    width: 95%;
  }
`

export default SwatchPickerStyle
