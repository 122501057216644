import styled from 'styled-components'

const IntroStyles = styled.div`
  .balloon {
    width: 50%;
  }

  .example-palette {
    transform: rotate(0.015turn);
    width: 60%;
  }

  .intro-characters {
    width: 40%;
  }

  .intro-nav {
    text-decoration: none;
    color: black;
    &:hover {
      color: red;
    }
  }
`

export default IntroStyles
