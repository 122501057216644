import { useEffect, useState } from 'react'
import styled from 'styled-components'
import chroma from 'chroma-js'
import CopyToClipboard from 'react-copy-to-clipboard'

const ResultStyle = styled.div`
  width: 85%;
  min-height: 5rem;
  border: 1px dashed gray;
  background-color: ${(props) => props.bg || 'white'};
  p {
    color: ${(props) => props.textColors || 'black'};
  }
  .bold {
    font-weight: 700;
  }
`

const ResultDiv = (colorResult) => {
  const [textColor, setTextColor] = useState('black')
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    if (chroma(textColor).luminance() < 0.2) {
      setTextColor('white')
    } else {
      setTextColor('black')
    }
  }, [colorResult])

  const copyDisplay = () => {
    setTimeout(() => {
      setCopied(false)
    }, 1000)
  }

  return (
    <CopyToClipboard
      text={colorResult.color}
      onCopy={() => setCopied({ copied: true })}
      onClick={copyDisplay()}
    >
      <ResultStyle bg={colorResult.color} textColors={textColor}>
        <div>
          <p className="text-center mt-3 mb-0">{colorResult.color}</p>
          {copied && <p className="text-center bold p-0 m-0">Copied</p>}
        </div>
      </ResultStyle>
    </CopyToClipboard>
  )
}

export default ResultDiv
