import styled from 'styled-components'

const GlobalStyle = styled.div`
  .text-white {
    color: white;
  }
  .text-black {
    color: black;
  }
  .text-gray {
    color: #8b9898;
  }
  .cramps-red {
    font-family: 'Cramps', 'akaya';
    color: darkred;
  }
  .cramps-gray {
    font-family: 'Cramps';
    color: darkgray;
  }
  ${'' /* buttons */}
  .filter-button {
    button {
      background-color: transparent;
      width: 75%;
      max-width: 97%;
      border: 1px solid transparent;
      ${'' /* border-radius: 1rem; */}
      padding: 0.25rem;
      color: #646464;
      font-size: 1.25em;
      ${'' /* text-decoration: underline; */}
      font-weight: 500;
      &:hover {
        text-decoration: underline;
      }
      &:active {
        transform: translateY(2px);
      }
    }
  }
  .color-div {
    background-color: white;
    height: 1rem;
    width: 1rem;
    border-radius: 30%;
  }

  .box-shadow {
    box-shadow: 0 7px 10px 2px #0000001a;
  }

  .BTN-left-Round {
    border-radius: 0.25rem 0 0 0.25rem;
    background-color: white;
    padding: 0.5rem;
    border: 1px solid lightgray;
    color: gray;
    &:hover {
      color: black;
    }
    &:active {
      transform: translateY(2px);
      color: black;
      border: 1px solid black;
    }
  }
  .BTN-right-Round {
    border-radius: 0 0.25rem 0.25rem 0;
    background-color: white;
    padding: 0.5rem;
    border-left: none;
    border: 1px solid lightgray;
    color: gray;
    &:hover {
      color: black;
    }
    &:active {
      transform: translateY(2px);
      color: black;
      border: 1px solid black;
    }
  }
  .BTN-Middle {
    background-color: white;
    padding: 0.5rem;
    border-left: none;
    border: 1px solid lightgray;
    color: gray;
    &:hover {
      color: black;
    }
    &:active {
      transform: translateY(2px);
      color: black;
      border: 1px solid black;
    }
  }
  .BTN-Single {
    border-radius: 0.25rem;
    background-color: white;
    padding: 0.5rem;
    border: 1px solid lightgray;
    color: gray;
    &:hover {
      color: black;
    }
    &:active {
      transform: translateY(2px);
      color: black;
      border: 1px solid black;
    }
    &:disabled {
      text-decoration: line-through;
    }
  }

  ${'' /* navbar */}
  .Nav-Text {
    color: white;
    text-decoration: none;
    font-family: 'FredokaOne';
    font-size: 1.2em;
    &:hover {
      color: red;
    }
  }

  .border-bottom {
    padding-bottom: 0.25rem;
    border-bottom: 1px solid lightgray;
  }
  ${
    '' /* .square-1 {
    background-color: black;
    border: 1px solid white;
    width: 1.2rem;
    height: 1.2rem;
    position: absolute;
    top: 1.5rem;
    left: 22.4rem;
  }
  .square-2 {
    background-color: white;
    border: 1px solid black;
    width: 1.2rem;
    height: 1.2rem;
    position: absolute;
    top: 2.6rem;
    left: 21.2rem;
  } */
  }

  .page-title {
    font-family: 'cramps';
    @media screen and (max-width: 600px) {
      font-size: 1.5em;
    }
    @media screen and (max-width: 400px) {
      font-size: 1.25em;
    }
  }
  .nav-box {
    ${'' /* box-shadow: inset -1px 0px 7px 5px lightgray; */}
    border-right: 1px solid lightgray;
    background-color: white;
  }

  .gradient-header {
    font-size: 4rem;
    font-weight: 700;
    ${
      '' /* background: -webkit-linear-gradient(bottom right, #ef9e9f, #efc797, #edefa7, #bbefb0, #8be6ef, #91b5ef, #aea3ef); */
    }
    background: -webkit-linear-gradient(bottom right, #540d6e, #f86624, #ee4266, #ffd23f, #3bceac, #0ead69, #184f9d, #184f9d, #8338ec);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: white;
    @media screen and (max-width: 400px) {
      font-size: 3rem;
    }
  }

  .fredoka {
    font-family: 'Fredoka';
  }
  .fredoka-one {
    font-family: 'FredokaOne';
  }
  input,
  h5,
  button {
    font-family: 'Fredoka';
  }

  .nav-icon {
    height: 2rem;
  }

  .search-icon {
    height: 1.25rem;
    padding-bottom: 0.1rem;
  }

  .search-header {
    font-weight: 700;
    font-size: 1.75em;
    ${'' /* text-decoration: underline; */}
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
  }

  .header-balloon {
    height: 5rem;
    margin-left: -2rem;
    margin-top: 0.5rem;
  }

  .little-paintbrush {
    height: 4.5rem;
  }

  .magnifying-glass {
    font-size: 2em;
    display: inline-block;
    width: 0.4em;
    box-sizing: content-box;
    height: 0.4em;
    border: 0.1em solid black;
    position: relative;
    border-radius: 0.35em;
  }
  .magnifying-glass:before {
    content: '';
    display: inline-block;
    position: absolute;
    right: -0.25em;
    bottom: -0.1em;
    border-width: 0;
    background: black;
    width: 0.35em;
    height: 0.08em;
    transform: rotate(45deg);
  }
`
export default GlobalStyle
