import { Link } from 'react-router-dom'
import '../../App.css'
import paletteIconWhite from '../../Images/palette_white.svg'
import paletteIconRed from '../../Images/palette_red.svg'
import logInWhite from '../../Images/login_white.svg'
import logInRed from '../../Images/login_red.svg'
import starWhite from '../../Images/grade_white.svg'
import starRed from '../../Images/grade_red.svg'
import balloon from '../../Images/balloon.png'
import { useState } from 'react'

const Navbar = () => {
  const [starColor, setStarColor] = useState(starWhite)
  const [paletteIconColor, setPaletteIconColor] = useState(paletteIconWhite)
  const [logInColor, setLogInColor] = useState(logInWhite)

  return (
    <header className="App-header d-flex justify-content-between">
      <div className="col-6 col-sm-7 d-flex justify-content-start">
        <h1 className="d-flex align-items-end gradient-header px-4 fredoka-one">
          ColorPop
        </h1>
        <Link to="/">
          <img
            src={balloon}
            className="d-none d-sm-block header-balloon"
            alt="Color Pop balloon"
          />
        </Link>
      </div>
      <div className="col-6 col-sm-5 d-flex align-items-end justify-content-end mb-2">
        <nav className="p-3">
          <Link
            to="/Popular"
            className="p-2 fredokaOne"
            onMouseEnter={() => setStarColor(starRed)}
            onMouseLeave={() => setStarColor(starWhite)}
          >
            <img src={starColor} className="nav-icon" alt="popular palettes" />
          </Link>
          <Link
            to="/MySwatches"
            className="p-2 fredokaOne"
            onMouseEnter={() => setPaletteIconColor(paletteIconRed)}
            onMouseLeave={() => setPaletteIconColor(paletteIconWhite)}
          >
            <img
              src={paletteIconColor}
              className="nav-icon"
              alt="build a palette"
            />
          </Link>
          <Link
            to="/SignIn"
            className="p-2 fredokaOne"
            onMouseEnter={() => setLogInColor(logInRed)}
            onMouseLeave={() => setLogInColor(logInWhite)}
          >
            <img src={logInColor} className="nav-icon" alt="sign in" />
          </Link>
        </nav>
      </div>
    </header>
  )
}

export default Navbar
