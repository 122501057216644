import React, { useState, useEffect } from 'react'
import SwatchDiv from './SwatchDiv'
import SwatchPickerStyle from './SwatchPickerStyle'
import { auth, db } from '../SignIn/firebase-config'
import { collection, doc, setDoc } from 'firebase/firestore'

const Swatch = ({ setMySwatches, swatch, mySwatches }) => {
  const [showSaveWarning, setShowSaveWarning] = useState(false)

  const saveButton = () => {
    if (!auth.currentUser) {
      setShowSaveWarning(true)
      setTimeout(() => {
        setShowSaveWarning(false)
      }, 1000)
      return
    } else {
      setMySwatches([...mySwatches, swatch])
      const userCollectionRef = collection(db, auth.currentUser.email)

      if (swatch.colors.length === 1) {
        setDoc(doc(userCollectionRef, `${swatch.name}`), {
          name: `${swatch.name}`,
          id: `${swatch.id}`,
          colors: [
            {
              color: `${swatch.colors[0].color},`,
              id: `${swatch.colors[0].id}`,
            },
          ],
        })
      }
      if (swatch.colors.length === 2) {
        setDoc(doc(userCollectionRef, `${swatch.name}`), {
          name: `${swatch.name}`,
          id: `${swatch.id}`,
          colors: [
            {
              color: `${swatch.colors[0].color}`,
              id: `${swatch.colors[0].id}`,
            },
            {
              color: `${swatch.colors[1].color}`,
              id: `${swatch.colors[1].id}`,
            },
          ],
        })
      }
      if (swatch.colors.length === 3) {
        setDoc(doc(userCollectionRef, `${swatch.name}`), {
          name: `${swatch.name}`,
          id: `${swatch.id}`,
          colors: [
            {
              color: `${swatch.colors[0].color}`,
              id: `${swatch.colors[0].id}`,
            },
            {
              color: `${swatch.colors[1].color}`,
              id: `${swatch.colors[1].id}`,
            },
            {
              color: `${swatch.colors[2].color}`,
              id: `${swatch.colors[2].id}`,
            },
          ],
        })
      }
      if (swatch.colors.length === 4) {
        setDoc(doc(userCollectionRef, `${swatch.name}`), {
          name: `${swatch.name}`,
          id: `${swatch.id}`,
          colors: [
            {
              color: `${swatch.colors[0].color}`,
              id: `${swatch.colors[0].id}`,
            },
            {
              color: `${swatch.colors[1].color}`,
              id: `${swatch.colors[1].id}`,
            },
            {
              color: `${swatch.colors[2].color}`,
              id: `${swatch.colors[2].id}`,
            },
            {
              color: `${swatch.colors[3].color}`,
              id: `${swatch.colors[3].id}`,
            },
          ],
        })
      }
      if (swatch.colors.length === 5) {
        setDoc(doc(userCollectionRef, `${swatch.name}`), {
          name: `${swatch.name}`,
          id: `${swatch.id}`,
          colors: [
            {
              color: `${swatch.colors[0].color}`,
              id: `${swatch.colors[0].id}`,
            },
            {
              color: `${swatch.colors[1].color}`,
              id: `${swatch.colors[1].id}`,
            },
            {
              color: `${swatch.colors[2].color}`,
              id: `${swatch.colors[2].id}`,
            },
            {
              color: `${swatch.colors[3].color}`,
              id: `${swatch.colors[3].id}`,
            },
            {
              color: `${swatch.colors[4].color}`,
              id: `${swatch.colors[4].id}`,
            },
          ],
        })
      }
      if (swatch.colors.length === 6) {
        setDoc(doc(userCollectionRef, `${swatch.name}`), {
          name: `${swatch.name}`,
          id: `${swatch.id}`,
          colors: [
            {
              color: `${swatch.colors[0].color}`,
              id: `${swatch.colors[0].id}`,
            },
            {
              color: `${swatch.colors[1].color}`,
              id: `${swatch.colors[1].id}`,
            },
            {
              color: `${swatch.colors[2].color}`,
              id: `${swatch.colors[2].id}`,
            },
            {
              color: `${swatch.colors[3].color}`,
              id: `${swatch.colors[3].id}`,
            },
            {
              color: `${swatch.colors[4].color}`,
              id: `${swatch.colors[4].id}`,
            },
            {
              color: `${swatch.colors[5].color}`,
              id: `${swatch.colors[5].id}`,
            },
          ],
        })
      }
      if (swatch.colors.length === 7) {
        setDoc(doc(userCollectionRef, `${swatch.name}`), {
          name: `${swatch.name}`,
          id: `${swatch.id}`,
          colors: [
            {
              color: `${swatch.colors[0].color}`,
              id: `${swatch.colors[0].id}`,
            },
            {
              color: `${swatch.colors[1].color}`,
              id: `${swatch.colors[1].id}`,
            },
            {
              color: `${swatch.colors[2].color}`,
              id: `${swatch.colors[2].id}`,
            },
            {
              color: `${swatch.colors[3].color}`,
              id: `${swatch.colors[3].id}`,
            },
            {
              color: `${swatch.colors[4].color}`,
              id: `${swatch.colors[4].id}`,
            },
            {
              color: `${swatch.colors[5].color}`,
              id: `${swatch.colors[5].id}`,
            },
            {
              color: `${swatch.colors[6].color}`,
              id: `${swatch.colors[6].id}`,
            },
          ],
        })
      }
      if (swatch.colors.length === 8) {
        setDoc(doc(userCollectionRef, `${swatch.name}`), {
          name: `${swatch.name}`,
          id: `${swatch.id}`,
          colors: [
            {
              color: `${swatch.colors[0].color}`,
              id: `${swatch.colors[0].id}`,
            },
            {
              color: `${swatch.colors[1].color}`,
              id: `${swatch.colors[1].id}`,
            },
            {
              color: `${swatch.colors[2].color}`,
              id: `${swatch.colors[2].id}`,
            },
            {
              color: `${swatch.colors[3].color}`,
              id: `${swatch.colors[3].id}`,
            },
            {
              color: `${swatch.colors[4].color}`,
              id: `${swatch.colors[4].id}`,
            },
            {
              color: `${swatch.colors[5].color}`,
              id: `${swatch.colors[5].id}`,
            },
            {
              color: `${swatch.colors[6].color}`,
              id: `${swatch.colors[6].id}`,
            },
            {
              color: `${swatch.colors[7].color}`,
              id: `${swatch.colors[7].id}`,
            },
          ],
        })
      }
      if (swatch.colors.length === 9) {
        setDoc(doc(userCollectionRef, `${swatch.name}`), {
          name: `${swatch.name}`,
          id: `${swatch.id}`,
          colors: [
            {
              color: `${swatch.colors[0].color}`,
              id: `${swatch.colors[0].id}`,
            },
            {
              color: `${swatch.colors[1].color}`,
              id: `${swatch.colors[1].id}`,
            },
            {
              color: `${swatch.colors[2].color}`,
              id: `${swatch.colors[2].id}`,
            },
            {
              color: `${swatch.colors[3].color}`,
              id: `${swatch.colors[3].id}`,
            },
            {
              color: `${swatch.colors[4].color}`,
              id: `${swatch.colors[4].id}`,
            },
            {
              color: `${swatch.colors[5].color}`,
              id: `${swatch.colors[5].id}`,
            },
            {
              color: `${swatch.colors[6].color}`,
              id: `${swatch.colors[6].id}`,
            },
            {
              color: `${swatch.colors[7].color}`,
              id: `${swatch.colors[7].id}`,
            },
            {
              color: `${swatch.colors[8].color}`,
              id: `${swatch.colors[8].id}`,
            },
          ],
        })
      }
      if (swatch.colors.length === 10) {
        setDoc(doc(userCollectionRef, `${swatch.name}`), {
          name: `${swatch.name}`,
          id: `${swatch.id}`,
          colors: [
            {
              color: `${swatch.colors[0].color}`,
              id: `${swatch.colors[0].id}`,
            },
            {
              color: `${swatch.colors[1].color}`,
              id: `${swatch.colors[1].id}`,
            },
            {
              color: `${swatch.colors[2].color}`,
              id: `${swatch.colors[2].id}`,
            },
            {
              color: `${swatch.colors[3].color}`,
              id: `${swatch.colors[3].id}`,
            },
            {
              color: `${swatch.colors[4].color}`,
              id: `${swatch.colors[4].id}`,
            },
            {
              color: `${swatch.colors[5].color}`,
              id: `${swatch.colors[5].id}`,
            },
            {
              color: `${swatch.colors[6].color}`,
              id: `${swatch.colors[6].id}`,
            },
            {
              color: `${swatch.colors[7].color}`,
              id: `${swatch.colors[7].id}`,
            },
            {
              color: `${swatch.colors[8].color}`,
              id: `${swatch.colors[8].id}`,
            },
            {
              color: `${swatch.colors[9].color}`,
              id: `${swatch.colors[9].id}`,
            },
          ],
        })
      }
    }
  }

  return (
    <SwatchPickerStyle>
      <div className="swatch-container m-3 box-shadow">
        <h5 className="my-auto text-gray fredoka pb-2">{swatch.name}</h5>
        <div className="col mb-2">
          {swatch.colors.map((color, index) => (
            <SwatchDiv bg={color} key={index} />
          ))}
        </div>
        <div className="d-flex justify-content-between mt-3">
          <button className="BTN-Single" onClick={saveButton}>
            Save
          </button>
          {showSaveWarning && <p className="mt-2 mb-0">Log in required</p>}
        </div>
      </div>
    </SwatchPickerStyle>
  )
}

export default Swatch
