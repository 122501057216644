import {
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signOut,
  signInWithEmailAndPassword,
} from 'firebase/auth'
import { useState } from 'react'
import SignInStyles from './SignIn/SignInStyles'
import { auth, db } from './SignIn/firebase-config'
import { doc, setDoc } from 'firebase/firestore'

const SignIn = () => {
  const [registerEmail, setRegisterEmail] = useState('')
  const [registerPassword, setRegisterPassword] = useState('')
  const [loginEmail, setLoginEmail] = useState('')
  const [loginPassword, setLoginPassword] = useState('')
  const [user, setUser] = useState()
  const [errorWarning, setErrorWarning] = useState(null)
  const [incorrectPassWarn, setIncorrectPassWarn] = useState(false)

  // const userCollectionRef = collection(db, "test@mail.com")

  // onKeyUp={(evt) => {if(evt.keyCode == 13) {"trigger save or log in"}}}
  // ? onFocus to trigger which 'enter' is triggered?

  onAuthStateChanged(auth, (currentUser) => {
    setUser(currentUser)
  })

  const register = async () => {
    try {
      const user = await createUserWithEmailAndPassword(
        auth,
        registerEmail,
        registerPassword
      )
      const id = Math.floor(Math.random() * 1000000) + 1
      setUser(user.user.uid)
      setDoc(doc(db, registerEmail, 'example'), {
        name: 'example',
        id: id,
        colors: [
          { color: '#18879d', id: id + 1 },
          { color: '#184f9d', id: id + 2 },
          { color: '#49189d', id: id + 3 },
          { color: '#000001', id: id + 4 },
        ],
      })
      setErrorWarning(null)
    } catch (error) {
      console.log(error.message)
      setErrorWarning(error.message)
    }
  }

  const logIn = async () => {
    try {
      const user = await signInWithEmailAndPassword(
        auth,
        loginEmail,
        loginPassword
      )
      setUser(user.user.uid)
      setIncorrectPassWarn(false)
    } catch (error) {
      console.log(error.message, 'incorrect pass')
      setIncorrectPassWarn(true)
    }
  }

  const logOut = async () => {
    await signOut(auth)
    setUser(null)
  }

  return (
    <SignInStyles className="d-flex justify-content-center">
      <div className="sign-in-card my-5 p-3 p-sm-5 row">
        <div className="p-sm-5 col-12 col-lg-6 border-bottom pb-3 mb-3 mb-sm-0">
          <h3 className="fredoka">Login</h3>
          <input
            placeholder="Email.."
            onChange={(event) => {
              setLoginEmail(event.target.value)
            }}
          />
          <input
            placeholder="Password.."
            className="my-2"
            onChange={(event) => {
              setLoginPassword(event.target.value)
            }}
          />
          <br />
          <div className="">
            <button onClick={logIn} className="BTN-Single">
              Log In
            </button>
            {incorrectPassWarn && (
              <p className="p-2 px-0">Incorrect Email or Password</p>
            )}
          </div>
        </div>
        <div className="p-sm-5 col-12 col-lg-6 border-bottom pb-3 mb-3 mb-sm-0">
          <h3 className="fredoka">Register User</h3>
          <input
            placeholder="Email..."
            onChange={(event) => {
              setRegisterEmail(event.target.value)
            }}
          />
          <input
            required
            placeholder="Password..."
            className="my-2"
            minLength="6"
            onChange={(event) => {
              setRegisterPassword(event.target.value)
            }}
          />
          <br />
          <button onClick={register} className="BTN-Single">
            {' '}
            Create User
          </button>
          {errorWarning && (
            <p className=" p-2 px-0">
              An Error Occurred: {errorWarning.slice(15)}{' '}
            </p>
          )}
        </div>
        <div className="col mt-5">
          {user && (
            <>
              <h5 className="text-center">Logged In As: {user?.email}</h5>
              <div className="d-flex justify-content-center">
                <button onClick={logOut} className="BTN-Single mx-3">
                  Sign Out
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </SignInStyles>
  )
}

export default SignIn
