import React from 'react'
import SwatchDiv from './SwatchDiv'
import SwatchPickerStyle from './SwatchPickerStyle'
import { auth, db } from '../SignIn/firebase-config'
import { doc, deleteDoc } from 'firebase/firestore'

const MySwatch = (props) => {
  const removeSwatch = async () => {
    props.setMySwatches(
      props.mySwatches.filter((swatch) => swatch.name !== props.swatch.name)
    )
    const swatchDoc = doc(
      db,
      `${auth.currentUser.email}`,
      `${props.swatch.name}`
    )
    await deleteDoc(swatchDoc)
  }

  const editSwatch = () => {
    props.setColorsInSwatch(props.swatch.colors)
    props.setSwatchName(props.swatch.name)
    removeSwatch()
  }

  return (
    <SwatchPickerStyle>
      <div className="swatch-container m-3 box-shadow">
        <h5 className="fredoka my-auto">{props.swatch.name}</h5>
        <div className="color-row">
          {props.swatch.colors.map((color, index) => (
            <SwatchDiv bg={color} key={index} />
          ))}
        </div>
        <div className="d-flex justify-content-between mt-3">
          <div className="d-flex justify-content-center">
            <button className=" BTN-left-Round" onClick={editSwatch}>
              Edit
            </button>
            <button className=" BTN-right-Round" onClick={removeSwatch}>
              Remove
            </button>
          </div>
        </div>
      </div>
    </SwatchPickerStyle>
  )
}

export default MySwatch
