import styled from 'styled-components'

const ConverterStyle = styled.div`
  .header-converter {
    color: black;
    font-family: 'Fredoka';
  }
  .input-converter {
    width: 100%;
    border: 1px solid lightgray;
  }
  .border-converter {
    border: 1px solid lightgray;
    border-radius: 0.25rem;
    background-color: white;
    height: 24.3rem;
  }
  .border-bottom {
    border-bottom: 1px solid lightgray;
  }
`

export default ConverterStyle
